var hooxSliderReviews = document.querySelectorAll('.hoox-reviews__items');

if (!!hooxSliderReviews.length) {
  hooxSliderReviews = Array.from(hooxSliderReviews);

  hooxSliderReviews.forEach(element => {
    var slides = element.querySelectorAll('.swiper-slide');
    var spaceBetweenValue = 48;

    var swiperOptions = {
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      centeredSlides: true,
      spaceBetween: 10,
      pagination: {
        el: '.hoox-reviews__dots',
        clickable: true
      },
      navigation: {
        nextEl: '.hoox-swiper-reviews-arrow-next',
        prevEl: '.hoox-swiper-reviews-arrow-prev',
      }
    };

    if (window.innerWidth < 1023 && slides.length > 1) {
      // Initialize on mobile with more than 1 slide
      new Swiper(element, swiperOptions);
    } else if (window.innerWidth >= 1023 && slides.length > 3) {
      // Initialize on desktop with more than 3 slides
      var parentElement = element.closest(".hoox-reviews-lp-1");
      if (parentElement) {
        spaceBetweenValue = 32;
      }
      swiperOptions.breakpoints = {
        767: {
          slidesPerView: 3,
          slidesPerGroup: 1,
          spaceBetween: spaceBetweenValue, // Use the defined spaceBetween
          touchRatio: 0,
        }
      };
      new Swiper(element, swiperOptions);
    } else {
      // Hide navigation arrows if Swiper is not initialized
      var navigation = element.querySelector('.hoox-reviews__swiper-navigation');
      if (navigation) {
        navigation.style.display = 'none';
        navigation.style.height = '0';
      }
    }
  });
}
